import { memo } from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import useRoutesTest from '@/pages/TestPage/useRoutesTest.jsx'
import useRoutesLogin from '@/pages/LoginPage/useRoutesLogin.jsx'
import useRoutesRegister from "@/pages/RegisterPage/useRoutesRegister";
import useRoutesFindPwd from "@/pages/LoginPage/useRoutesFindPwd";
import useRoutesMain from '@/pages/MainPage/useRoutesMain.jsx'
import useRoutesShare from '@/pages/SharePage/useRoutesShare.jsx'
import useRoutesUiDemo from '@/pages/UiDemo/useRoutesDemo.jsx'
import useRoutesSfDemo from '@/pages/SfDemo/useRoutesDemo.jsx'
import useRoutesUcDemo from '@/pages/UcDemo/useRoutesDemo.jsx'
import useRoutesDpMap from '@/pages/DpMap/useRoutesMap.jsx'
import useRoutesSearch from '@/pages/PrjSearch/useRoutesSearch.jsx'
import useRoutesFrameworkIDE from '@/pages/FrameworkIDE/useRoutesFrameworkIDE.jsx'
import useRoutesVmIDE from '@/pages/VmIDE/useRoutesVmIDE.jsx'
import useRoutesApiIDE from '@/pages/ApiIDE/useRoutesApiIDE.jsx'
import useRoutesFlowEditor from "@/pages/FlowEditor/useRoutesFlowEditor.jsx"
import useRoutesFlowEditorTest from "@/pages/FlowEditor/useRoutesFlowEditorTest.jsx"
import useRoutesShortUrl from "./useRoutesShortUrl.mjs"
import useFormilyPreview from '@/pages/FormilyPreview/useFormilyPreview';

const Router = () => {
    const router = createBrowserRouter([
        useRoutesTest(),
        useRoutesLogin(),
        useRoutesRegister(),
        useRoutesFindPwd(),
        useRoutesMain(),
        useRoutesShare(),
        useRoutesUiDemo(),
        useRoutesSfDemo(),
        useRoutesUcDemo(),
        useRoutesDpMap(),
        useRoutesSearch(),
        useRoutesFrameworkIDE(),
        useRoutesVmIDE(),
        useRoutesApiIDE(),
        useRoutesFlowEditor(),
        useRoutesFlowEditorTest(),
        useRoutesShortUrl(),
        ...useFormilyPreview(),
    ])

    return <RouterProvider router={router} />
}

export default Router
