import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Input, Tabs, Checkbox, Badge, Row, Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from "@/script/runAsync.mjs";
import useDocTitle from '@/hooks/useDocTitle.mjs';
import { useUser } from '@/biz/user.mjs'
import BasMapTab from './BasMapTab.jsx';
import BfTab from './BfTab.jsx';
import BdTab from './BdTab.jsx';
import SvrTab from './SvrTab.jsx';
import SfTab from './SfTab.jsx';
import UcTab from './UcTab.jsx';
import UiTab from './UiTab.jsx';
import DtTab from './DtTab.jsx';
import DvTab from './DvTab.jsx';
import CdgTab from './CdgTab.jsx';
import LsiTab from './LsiTab.jsx';
import FcTab from './FcTab.jsx';
import DtoTab from './DtoTab.jsx';
import DesTab from './DesTab.jsx';
import SelectProjectList from '@/pages/MainPage/dialogs/SelectProjectList';
import SelectPattern from './SelectPattern.jsx';

const Pane = () => {
    useDocTitle('搜索');
    const [searchParams] = useSearchParams()
    const param = Object.fromEntries(searchParams.entries());
    const keyword = param.keyword;

    const { TabPane } = Tabs;
    const http = useHTTP();
    const user = useUser()
    const ref = useRef({ count: 0 });

    const options = [
        // -------集成地图-------
        {
            value: 'BIZ_MAP',
            label: '业务模型',
        },
        {
            value: 'DATA_MAP',
            label: '库表模型',
        },
        {
            value: 'SF_MAP',
            label: '功能模型',
        },
        {
            value: 'UI_MAP',
            label: '界面模型',
        },
        {
            value: 'INF_MAP',
            label: '接口模型',
        },
        {
            value: 'FC_MAP',
            label: '程序模型',
        },

        // -------制品地图-------
        {
            value: 'DES',
            label: '功能单',
        },
        {
            value: 'BF',
            label: '业务流程',
        },
        {
            value: 'BD',
            label: '业务对象',
        },
        {
            value: 'SVR',
            label: '业务服务',
        },
        {
            value: 'SF',
            label: '系统功能',
        },
        {
            value: 'UC',
            label: '集成用例',
        },
        {
            value: 'UI',
            label: '用户界面',
        },
        {
            value: 'DT',
            label: '数据表',
        },
        {
            value: 'DV',
            label: '数据视图',
        },
        {
            value: 'CDG',
            label: '标准字段组',
        },
        {
            value: 'LSI',
            label: '外联接口',
        },
        {
            value: 'FC',
            label: '程序能力',
        },
        {
            value: 'DTO',
            label: 'DTO',
        },
    ]

    // -----------------------List-----------------------
    const [bizMapList, setBizMapList] = useState([]);
    const [dataMapList, setDataMapList] = useState([]);
    const [sfMapList, setSfMapList] = useState([]);
    const [uiMapList, setUiMapList] = useState([]);
    const [infMapList, setInfMapList] = useState([]);
    const [fcMapList, setFcMapList] = useState([]);

    // 业务
    const [desList, setDesList] = useState([]);
    const [bfList, setBfList] = useState([]);
    const [bdList, setBdList] = useState([]);
    const [svrList, setSvrList] = useState([]);

    // 功能、界面
    const [sfList, setSfList] = useState([]);
    const [ucList, setUcList] = useState([]);
    const [uiList, setUiList] = useState([]);

    // 数据、接口、程序
    const [dtList, setDtList] = useState([]);
    const [dvList, setDvList] = useState([]);
    const [cdgList, setCdgList] = useState([]);
    const [lsiList, setLsiList] = useState([]);
    const [fcList, setFcList] = useState([]);
    const [dtoList, setDtoList] = useState([]);

    // -----------------------setRows-----------------------
    const [bizMapRows, setBizMapRows] = useState([]);
    const [dataMapRows, setDataMapRows] = useState([]);
    const [sfMapRows, setSfMapRows] = useState([]);
    const [uiMapRows, setUiMapRows] = useState([]);
    const [infMapRows, setInfMapRows] = useState([]);
    const [fcMapRows, setFcMapRows] = useState([]);

    // 业务
    const [desRows, setDesRows] = useState([]);
    const [bfRows, setBfRows] = useState([]);
    const [bdRows, setBdRows] = useState([]);
    const [svrRows, setSvrRows] = useState([]);

    // 功能、界面
    const [sfRows, setSfRows] = useState([]);
    const [ucRows, setUcRows] = useState([]);
    const [uiRows, setUiRows] = useState([]);

    // 数据、接口、程序
    const [dtRows, setDtRows] = useState([]);
    const [dvRows, setDvRows] = useState([]);
    const [cdgRows, setCdgRows] = useState([]);
    const [lsiRows, setLsiRows] = useState([]);
    const [fcRows, setFcRows] = useState([]);
    const [dtoRows, setDtoRows] = useState([]);

    // -----------------------Loading-----------------------
    const [bizMapLoading, setBizMapLoading] = useState([]);
    const [dataMapLoading, setDataMapLoading] = useState([]);
    const [sfMapLoading, setSfMapLoading] = useState([]);
    const [uiMapLoading, setUiMapLoading] = useState([]);
    const [infMapLoading, setInfMapLoading] = useState([]);
    const [fcMapLoading, setFcMapLoading] = useState([]);

    // 业务
    const [desLoading, setDesLoading] = useState(false);
    const [bfLoading, setBfLoading] = useState(false);
    const [bdLoading, setBdLoading] = useState(false);
    const [svrLoading, setSvrLoading] = useState(false);

    // 功能、界面
    const [sfLoading, setSfLoading] = useState(false);
    const [ucLoading, setUcLoading] = useState(false);
    const [uiLoading, setUiLoading] = useState(false);

    // 数据、接口、程序
    const [dtLoading, setDtLoading] = useState(false);
    const [dvLoading, setDvLoading] = useState(false);
    const [cdgLoading, setCdgLoading] = useState(false);
    const [lsiLoading, setLsiLoading] = useState(false);
    const [fcLoading, setFcLoading] = useState(false);
    const [dtoLoading, setDtoLoading] = useState(false);

    // check
    // const [checkedList, setCheckedList] = useState(options.map(v => v.value).filter(v => !['BIZ_MAP', 'DATA_MAP', 'SF_MAP', 'UI_MAP', 'INF_MAP', 'FC_MAP'].includes(v)));
    const [checkedList, setCheckedList] = useState(options.map(v => v.value).filter(v => ['BD'].includes(v)));
    const [checkAll, setCheckAll] = useState(true);
    const [indeterminate, setIndeterminate] = useState(true);

    useEffect(() => {
        init();
        doSearch(keyword);
    }, []);

    const init = () => {
        ref.current.prjId = user?.currPrjId;
        ref.current.sVer = user?.currPrjSver;
        ref.current.keyword = keyword;
    }

    const doSearch = async (keyword) => {
        if (!ref.current.prjId || !keyword) {
            return;
        }

        try {
            ref.current.count = 1;
            ref.current.keyword = keyword;
            const dpTypes = checkedList.length > 0 ? checkedList : options.map(v => v.value);

            for (const dpType of dpTypes) {
                doSearchRecord(dpType, keyword);
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    }

    const clearSearch = () => {
        setBizMapList([]);
        setDataMapList([]);
        setSfMapList([]);
        setUiMapList([]);
        setInfMapList([]);
        setFcMapList([]);

        setDesList([]);
        setBfList([]);
        setBdList([]);
        setSvrList([]);

        setSfList([]);
        setUcList([]);
        setUiList([]);

        setDtList([]);
        setDvList([]);
        setCdgList([]);

        setLsiList([]);
        setFcList([]);
        setDtoList([]);
    }

    const doSearchRecord = async (searchType, keyword) => {
        try {
            clearSearch();
            setLoad(searchType, true);

            // 搜索统计
            let result = await doSearchTotal(searchType, keyword);

            let data = [];
            let totalRow = result.totalRow;
            let pageSize = result.pageSize;
            let count = Math.ceil(totalRow / pageSize);

            // 分段检索
            for (var i = 0; i < count; i++) {
                let dataList = await doSearchData(searchType, keyword, i * pageSize);
                data = [...data, ...dataList];

                switch (searchType) {
                    case 'BIZ_MAP': setBizMapList(data); break;
                    case 'DATA_MAP': setDataMapList(data); break;
                    case 'SF_MAP': setSfMapList(data); break;
                    case 'UI_MAP': setUiMapList(data); break;
                    case 'INF_MAP': setInfMapList(data); break;
                    case 'FC_MAP': setFcMapList(data); break;
                    // 功能单
                    case 'DES': setDesList(data); break;
                    case 'BF': setBfList(data); break;
                    case 'BD': setBdList(data); break;
                    case 'SVR': setSvrList(data); break;
                    // 功能、界面
                    case 'SF': setSfList(data); break;
                    case 'UC': setUcList(data); break;
                    case 'UI': setUiList(data); break;
                    // 数据、接口、程序
                    case 'DT': setDtList(data); break;
                    case 'DV': setDvList(data); break;
                    case 'CDG': setCdgList(data); break;
                    case 'LSI': setLsiList(data); break;
                    case 'FC': setFcList(data); break;
                    case 'DTO': setDtoList(data); break;
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoad(searchType, false);
        }
    }

    const setLoad = (searchType, loadFlag) => {
        switch (searchType) {
            case 'BIZ_MAP': setBizMapLoading(loadFlag); break;
            case 'DATA_MAP': setDataMapLoading(loadFlag); break;
            case 'SF_MAP': setSfMapLoading(loadFlag); break;
            case 'UI_MAP': setUiMapLoading(loadFlag); break;
            case 'INF_MAP': setInfMapLoading(loadFlag); break;
            case 'FC_MAP': setFcMapLoading(loadFlag); break;
            // 功能单
            case 'DES': setDesLoading(loadFlag); break;
            case 'BF': setBfLoading(loadFlag); break;
            case 'BD': setBdLoading(loadFlag); break;
            case 'SVR': setSvrLoading(loadFlag); break;
            // 功能、界面
            case 'SF': setSfLoading(loadFlag); break;
            case 'UC': setUcLoading(loadFlag); break;
            case 'UI': setUiLoading(loadFlag); break;
            // 数据、接口、程序
            case 'DT': setDtLoading(loadFlag); break;
            case 'DV': setDvLoading(loadFlag); break;
            case 'CDG': setCdgLoading(loadFlag); break;
            case 'LSI': setLsiLoading(loadFlag); break;
            case 'FC': setFcLoading(loadFlag); break;
            case 'DTO': setDtoLoading(loadFlag); break;
        }
    }

    const doSearchTotal = async (searchType, keyword) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.post(`/prjs/search`, {
                        searchType,
                        keyword,
                        prjId: ref.current.prjId,
                        sVer: ref.current.sVer,
                        prjPatternId: ref.current.prjPatternId
                    });
                }, {
                error: { content: (err) => `搜索失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });
            return data ?? {};
        } catch (err) {
            console.log(err);
        }
    }

    const doSearchData = async (searchType, keyword, startRow) => {
        try {
            const data = await runAsync(
                async () => {
                    return await http.post(`/prjs/search/data`, {
                        searchType,
                        keyword,
                        startRow,
                        prjId: ref.current.prjId,
                        sVer: ref.current.sVer,
                        prjPatternId: ref.current.prjPatternId
                    });
                }, {
                error: { content: (err) => `搜索失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });
            return data.dataList ?? [];
        } catch (err) {
            console.log(err);
        }
    }

    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? options.map(v => v.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    }

    const BadgeExt = ({ name, dataList, loading }) => {
        return (
            <Badge size="small" offset={[loading ? 2 : 10, 0]} count={dataList.length} overflowCount={9999} status={loading ? 'processing' : 'success'}>
                {name}
            </Badge>
        );
    }

    const paneList = [
        <TabPane key={'BIZ_MAP'} tab={<BadgeExt name="业务模型" dataList={bizMapList} loading={bizMapLoading} />}>
            <BasMapTab param={ref} nodeType={'BM'} dataList={bizMapList} rows={bizMapRows} setRows={setBizMapRows} />
        </TabPane>,

        <TabPane key={'DATA_MAP'} tab={<BadgeExt name="库表模型" dataList={dataMapList} loading={dataMapLoading} />}>
            <BasMapTab param={ref} nodeType={'DM'} dataList={dataMapList} rows={dataMapRows} setRows={setDataMapRows} />
        </TabPane>,

        <TabPane key={'SF_MAP'} tab={<BadgeExt name="功能模型" dataList={sfMapList} loading={sfMapLoading} />}>
            <BasMapTab param={ref} nodeType={'FM'} dataList={sfMapList} rows={sfMapRows} setRows={setSfMapRows} />
        </TabPane>,

        <TabPane key={'UI_MAP'} tab={<BadgeExt name="界面模型" dataList={uiMapList} loading={uiMapLoading} />}>
            <BasMapTab param={ref} nodeType={'UM'} dataList={uiMapList} rows={uiMapRows} setRows={setUiMapRows} />
        </TabPane>,

        <TabPane key={'INF_MAP'} tab={<BadgeExt name="接口模型" dataList={infMapList} loading={infMapLoading} />}>
            <BasMapTab param={ref} nodeType={'LS'} dataList={infMapList} rows={infMapRows} setRows={setInfMapRows} />
        </TabPane>,

        <TabPane key={'FC_MAP'} tab={<BadgeExt name="程序模型" dataList={fcMapList} loading={fcMapLoading} />}>
            <BasMapTab param={ref} nodeType={'VM'} dataList={fcMapList} rows={fcMapRows} setRows={setFcMapRows} />
        </TabPane>,

        <TabPane key={'DES'} tab={<BadgeExt name="功能单" dataList={desList} loading={desLoading} />}>
            <DesTab param={ref} nodeType={'DES'} dataList={desList} rows={desRows} setRows={setDesRows} />
        </TabPane>,

        <TabPane key={'BF'} tab={<BadgeExt name="业务流程" dataList={bfList} loading={bfLoading} />}>
            <BfTab param={ref} dataList={bfList} rows={bfRows} setRows={setBfRows} />
        </TabPane>,

        <TabPane key={'BD'} tab={<BadgeExt name="业务对象" dataList={bdList} loading={bdLoading} />}>
            <BdTab param={ref} dataList={bdList} rows={bdRows} setRows={setBdRows} />
        </TabPane>,

        <TabPane key={'SVR'} tab={<BadgeExt name="业务服务" dataList={svrList} loading={svrLoading} />}>
            <SvrTab param={ref} dataList={svrList} rows={svrRows} setRows={setSvrRows} />
        </TabPane>,

        <TabPane key={'SF'} tab={<BadgeExt name="系统功能" dataList={sfList} loading={sfLoading} />}>
            <SfTab param={ref} dataList={sfList} rows={sfRows} setRows={setSfRows} />
        </TabPane>,

        <TabPane key={'UC'} tab={<BadgeExt name="集成用例" dataList={ucList} loading={ucLoading} />}>
            <UcTab param={ref} dataList={ucList} rows={ucRows} setRows={setUcRows} />
        </TabPane>,

        <TabPane key={'UI'} tab={<BadgeExt name="用户界面" dataList={uiList} loading={uiLoading} />}>
            <UiTab param={ref} dataList={uiList} rows={uiRows} setRows={setUiRows} />
        </TabPane>,

        <TabPane key={'DT'} tab={<BadgeExt name="数据表" dataList={dtList} loading={dtLoading} />}>
            <DtTab param={ref} dataList={dtList} rows={dtRows} setRows={setDtRows} />
        </TabPane>,

        <TabPane key={'DV'} tab={<BadgeExt name="数据视图" dataList={dvList} loading={dvLoading} />}>
            <DvTab param={ref} dataList={dvList} rows={dvRows} setRows={setDvRows} />
        </TabPane>,

        <TabPane key={'CDG'} tab={<BadgeExt name="标准字段组" dataList={cdgList} loading={cdgLoading} />}>
            <CdgTab param={ref} dataList={cdgList} rows={cdgRows} setRows={setCdgRows} />
        </TabPane>,

        <TabPane key={'LSI'} tab={<BadgeExt name="外联接口" dataList={lsiList} loading={lsiLoading} />}>
            <LsiTab param={ref} dataList={lsiList} rows={lsiRows} setRows={setLsiRows} />
        </TabPane>,

        <TabPane key={'FC'} tab={<BadgeExt name="程序能力" dataList={fcList} loading={fcLoading} />}>
            <FcTab param={ref} dataList={fcList} rows={fcRows} setRows={setFcRows} />
        </TabPane>,

        <TabPane key={'DTO'} tab={<BadgeExt name="DTO" dataList={dtoList} loading={dtoLoading} />}>
            <DtoTab param={ref} dataList={dtoList} rows={dtoRows} setRows={setDtoRows} />
        </TabPane>,
    ].filter(v => checkedList.includes(v.key));

    const searchInput = {
        width: '600px',
        borderRadius: '16px 0 0 16px',
        borderRight: 'none',
    }

    const searchBtn = {
        display: 'inline-block',
        width: '88px',
        height: '40px',
        outline: 0,
        border: '0 none',
        borderRadius: '0 16px 16px 0',
        fontSize: '14px',
        lineHeight: '32px',
        cursor: 'pointer',
        transition: 'all .2s ease -in',
        backgroundColor: '#0099CC',
        textAlign: 'center',
        color: '#fff',
    }

    const cssSearch = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    }

    const cssContent = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        marginLeft: '4px',
        height: '75vh',
    }

    return <div>
        <div css={{ display: 'flex', justifyContent: 'center', marginTop: '20px', fontSize: '12pt', fontWeight: 'bold' }}>
            <div>
                <span>项目：</span>
                <SelectProjectList
                    style={{ width: '400px' }}
                    prjId={ref.current.prjId}
                    onCall={(value) => {
                        ref.current.prjId = value.pkid;
                        ref.current.sVer = value.sVer;
                        ref.current.prjPatternId = null; // 迭代版
                        clearSearch();
                    }}
                />

                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

                {
                    ref.current.prjId &&
                    <>
                        <span>成品：</span>
                        <SelectPattern
                            style={{ width: '100px' }}
                            prjId={ref.current.prjId}
                            value={ref.current.prjPatternId}
                            onChange={(value) => {
                                ref.current.prjPatternId = value;
                                clearSearch();
                            }}
                            onCall={(value) => {
                                ref.current.patternRev = value;
                            }}
                        />
                    </>
                }
            </div>
        </div>

        <div css={cssSearch}>
            <Input
                css={searchInput}
                size="large"
                allowClear
                enterButton
                defaultValue={keyword}
                prefix={<SearchOutlined />}
                onChange={e => ref.current.keyword = e.target.value}
                onPressEnter={e => doSearch(e.target.value)}
            />
            <button css={searchBtn} onClick={() => doSearch(ref.current.keyword)}>搜索</button>
        </div>

        <div css={cssSearch} style={{ marginTop: '10px' }}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全部</Checkbox>
            {/* <Checkbox.Group value={checkedList} onChange={onChange} options={options} /> */}
            <Checkbox.Group value={checkedList} onChange={onChange}>
                <Row>
                    <Checkbox value="BIZ_MAP">业务模型</Checkbox>
                    <Checkbox value="DATA_MAP">库表模型</Checkbox>
                    <Checkbox value="SF_MAP">功能模型</Checkbox>
                    <Checkbox value="UI_MAP">界面模型</Checkbox>
                    <Checkbox value="INF_MAP">接口模型</Checkbox>
                    <Checkbox value="FC_MAP">程序模型</Checkbox>
                </Row>
                <Row>
                    <Checkbox value="DES">功能单</Checkbox>
                    <Checkbox value="BF">业务流程</Checkbox>
                    <Checkbox value="BD">业务对象</Checkbox>
                    <Checkbox value="SVR">业务服务</Checkbox>

                    <Checkbox value="SF">系统功能</Checkbox>
                    <Checkbox value="UC">集成用例</Checkbox>
                    <Checkbox value="UI">用户界面</Checkbox>

                    <Checkbox value="DT">数据表</Checkbox>
                    <Checkbox value="DV">数据视图</Checkbox>
                    <Checkbox value="CDG">标准字段组</Checkbox>

                    <Checkbox value="LSI">外联接口</Checkbox>
                    <Checkbox value="FC">程序能力</Checkbox>
                    <Checkbox value="DTO">DTO</Checkbox>
                </Row>
            </Checkbox.Group>
        </div>

        {
            (0 == ref.current.count) ?
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>请按“回车”键发起检索</div>
                :
                <div css={cssContent}>
                    <Tabs type="list" tabPosition={'top'} size="large">
                        {
                            paneList.map(v => v)
                        }
                    </Tabs>
                </div>
        }
    </div >;
}

export default Pane;
