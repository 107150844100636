import {Menu, Modal} from 'antd'
import {CustomerServiceFilled, CustomerServiceTwoTone, HeartFilled, MessageFilled, SecurityScanFilled, SmileTwoTone, ThunderboltFilled, UserOutlined} from '@ant-design/icons'
import {useUser, logout} from '@/biz/user.mjs'
import openWindow from '@/script/openWindow.mjs'
import mainMenuData from './mainMenuData';
import answer from '/answer.png';
import helpdoc from '/help_doc.png';
import usermanual from '/usermanual.png';
import drawicon from '/drawicon_42.png';

const MenuItem = ({ title, path, checkPrjType, ...props }) => {
    const user = useUser()

    const handleClick = e => {
        const target = e.domEvent.altKey ? '_self' : '_blank'

        if(checkPrjType){
            const {prjTypeCode} = user;
            if(prjTypeCode == 'ONLINE'){
                Modal.info({title: '提示信息', content: '当前项目是布署项目，本模型视图不适用'});
            } else{
                openWindow(`/${path}`, {}, target)
            }
        }else{
            openWindow(`/${path}`, {}, target)
        }
    }

    return (
        <Menu.Item
            {...props}
            onClick={handleClick}
        >{title}</Menu.Item>
    )
}

const LogoutMenuItem = (props) => {

    return (
        <Menu.Item
            {...props}
            onClick={logout}
        >退出登录</Menu.Item>
    )
}

const PersonalCenterMenuItem = (props) => {
    const handleClick = e => {
        const target = e.domEvent.altKey ? '_self' : '_blank'
        openWindow('/PersonalCenter', {}, target)
    }

    return (
        <Menu.Item
            {...props}
            onClick={handleClick}
        >个人中心</Menu.Item>
    )
}

const MainMenu = () => {
    const user = useUser()

    const mapMenuItems = (items) => {
        const mapMenuItem = ({ children, code, disabled, title, path, checkPrjType }) => {
            if (
                '1' === user?.isPlatformAdm ||
                'SM' !== code
            ) {
                if (children) {
                    return (
                        <Menu.SubMenu
                            key={title}
                            title={title}
                        >
                            {mapMenuItems(children)}
                        </Menu.SubMenu>
                    )
                }
                else {
                    return (
                        <MenuItem
                            disabled={disabled}
                            key={path}
                            title={title}
                            path={path}
                            checkPrjType={checkPrjType}
                        />
                    )
                }
            }
        }

        return items
            .map(mapMenuItem)
            .filter((a) => a)
    }

    const cssMenu = {
        justifySelf: 'end',
        flexWrap: 'wrap',
    }

    return (
        <Menu
            css={cssMenu}
            mode="horizontal"
            selectable={false}
            theme="dark"
            subMenuCloseDelay={0.3}
        >
            {mapMenuItems(mainMenuData)}

            {
                (user?.openUrl || user?.helpUrl) &&
                <Menu.SubMenu
                    icon={<CustomerServiceFilled style={{fontSize: 14, color: 'whitesmoke'}}/>}
                    key="outlink"
                    title='在线支持'
                >
                    {
                        user?.openUrl &&
                        <Menu.Item icon={<MessageFilled style={{fontSize: 14, color: '#73d13d'}}/>} onClick={() => { window.open(user?.openUrl) }}>问答社区</Menu.Item>
                    }
                    {/*{*/}
                    {/*    user?.helpUrl &&*/}
                    {/*    <Menu.Item icon={<ThunderboltFilled style={{fontSize: 14, color: '#ffc53d'}}/>} onClick={() => { window.open(user?.helpUrl) }}>体验指南</Menu.Item>*/}
                    {/*}*/}
                    <Menu.Item icon={<ThunderboltFilled style={{fontSize: 14, color: '#ffc53d'}}/>} onClick={() => { window.open('https://help.slmap.cn/smap-help-doc1-1/') }}>业务建模体验指南</Menu.Item>
                    <Menu.Item icon={<ThunderboltFilled style={{fontSize: 14, color: '#ffc53d'}}/>} onClick={() => { window.open('https://help.slmap.cn/smap-help-doc1-2/') }}>功能建模体验指南</Menu.Item>
                    <Menu.Item icon={<ThunderboltFilled style={{fontSize: 14, color: '#ffc53d'}}/>} onClick={() => { window.open('https://help.slmap.cn/smap-help-doc2/') }}>数据建模体验指南</Menu.Item>
                    <Menu.Item icon={<ThunderboltFilled style={{fontSize: 14, color: '#ffc53d'}}/>} onClick={() => { window.open('https://help.slmap.cn/smap-help-doc3/') }}>逆向数据建模体验指南</Menu.Item>
                    <Menu.Item icon={<ThunderboltFilled style={{fontSize: 14, color: '#ffc53d'}}/>} onClick={() => { window.open('https://help.slmap.cn/') }}>需求建模体验指南（业务+功能）</Menu.Item>

                    {/* <Menu.Item icon={<HeartFilled style={{fontSize: 14, color: '#ff4d4f'}}/>} onClick={() => window.open('https://smap.manual.hopedove.com')}>用户手册</Menu.Item> */}
                </Menu.SubMenu>
            }

            <Menu.SubMenu icon={<UserOutlined />} key="Personal" title={user?.name}>
                <PersonalCenterMenuItem key="PersonalCenter" />
                <LogoutMenuItem key="Logout" />
            </Menu.SubMenu>
        </Menu>
    )
}

export default MainMenu
