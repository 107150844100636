import {useEffect} from 'react'
import {css} from '@emotion/react'
import {notification} from 'antd'
import {join} from 'hopedove-dom/url'
import buildUrl from '@/script/buildUrl.mjs'
import {sharedEventBus} from '@/script/sharedEventBus.mjs'
import {useToken} from '@/biz/token.mjs'

const INTERVAL = 60000

const cssTimestamp = css({
    color: '#888',
    fontSize: 12,
    marginTop: 16,
})

const NotificationContent = ({
    erContent,
    erOptExtraData,
    erOptTypeCode,
    erTplLink,
    erTplLinkTitle,
    timeStamp,
}) => {
    if ('NEW_WINDOW' === erOptTypeCode) {
        const data = erOptExtraData ? JSON.parse(erOptExtraData) : {}
        const url = buildUrl(erTplLink, data)

        return (
            <div>
                <div>{erContent}</div>
                <div css={cssTimestamp}>{timeStamp}</div>

                <div>
                    <a
                        href={url}
                        rel="noreferrer"
                        target="_blank"
                    >
                        {erTplLinkTitle}
                    </a>
                </div>
            </div>
        )
    }
    else {
        return (
            <div>
                <div>{erContent}</div>
                <div>{timeStamp}</div>
            </div>
        )
    }
}

const id = crypto.randomUUID()
let es
let isOpened = false

const openSse = token => {
    if (isOpened) {
        isOpened = false
        return
    }

    if (! es) {
        es = new EventSource(
            join(
                import.meta.env.VITE_HTTP_BASE_URL,
                `/sse/connect/${token.token}`
            )
        )

        es.onmessage = e => sharedEventBus.publish(
            'sse_receive',
            JSON.parse(e.data)
        )
    }

    sharedEventBus.publish('sse_open', id)
}

export default () => {
    const token = useToken()

    useEffect(
        () => {
            if (! token) {
                return
            }

            const tid = setInterval(openSse, INTERVAL, token)

            const handleSseOpen = e => {
                if (id !== e.data) {
                    isOpened = true
                    es?.close()
                }
            }

            const handleSseReceive = e => {
                const {
                    erContent,
                    erOptExtraData,
                    erOptTypeCode,
                    erTplLink,
                    erTplLinkTitle,
                    erTplName,
                    timeStamp,
                } = e.data

                notification.open({
                    description: (
                        <NotificationContent
                            erContent={erContent}
                            erOptExtraData={erOptExtraData}
                            erOptTypeCode={erOptTypeCode}
                            erTplLink={erTplLink}
                            erTplLinkTitle={erTplLinkTitle}
                            timeStamp={timeStamp}
                        />
                    ),

                    message: erTplName,
                })
            }

            sharedEventBus.subscribe('sse_open', handleSseOpen)
            sharedEventBus.subscribe('sse_receive', handleSseReceive)

            return () => {
                clearInterval(tid)
                es?.close()
                sharedEventBus.unsubscribe('sse', handleSseOpen)
                sharedEventBus.unsubscribe('sse', handleSseReceive)
            }
        },

        [token]
    )
}
