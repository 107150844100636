import React from 'react';
// import { Table } from 'antd';
import openWindow from '@/script/openWindow.mjs';
import Page from '@/script/Page';
import Keyword from './Keyword.jsx';
import Table from './SearchTable';

const Pane = ({ param, nodeType, dataList, rows, setRows }) => {
    const keyword = param.current.keyword;
    const prjPatternId = param.current.prjPatternId;
    const patternRev = param.current.patternRev;

    const onLink = (record) => {
        const mapInits = {
            selectedNodes: [{ path: record.mapPath }],
        }

        if (record.mapVerId) {
            const inits = { ...mapInits };
            openWindow('/SoftwareMap', { id: record.mapVerId, mode: 'snapshot', inits, prjPatternId, patternRev });
        } else {
            if ('LS' == nodeType) {
                openWindow('/LsDetail', { lsId: record.dpId, mapInits });
            } else {
                openWindow('/SoftwareMap', { id: record.dpId, inits: mapInits });
            }
        }
    }

    const columns = [
        ('BM' == nodeType) && {
            key: 'bmNamePath',
            dataIndex: 'bmNamePath',
            title: '业务域',
            width: '100px'
        },
        ('DM' == nodeType) && {
            key: 'dmNamePath',
            dataIndex: 'dmNamePath',
            title: '数据域',
            width: '100px'
        },
        ('FM' == nodeType) && {
            key: 'fmNamePath',
            dataIndex: 'fmNamePath',
            title: '功能模块',
            width: '100px'
        },
        ('UM' == nodeType) && {
            key: 'umNamePath',
            dataIndex: 'umNamePath',
            title: '界面模块',
            width: '100px'
        },
        ('VM' == nodeType) && {
            key: 'vmNamePath',
            dataIndex: 'vmNamePath',
            title: '程序模块',
            width: '100px'
        },
        ('LS' == nodeType) && {
            key: 'lsName',
            dataIndex: 'lsName',
            title: '外联系统',
            width: '100px'
        },

        {
            key: 'keywordPath',
            dataIndex: 'keywordPath',
            title: '节点',
            onCell: (record, index) => {
                return { style: { whiteSpace: 'wrap' } };
            },
            render: (text, record) => {
                record.mapPath = record.keywordEncodePath;
                return <a onClick={() => onLink(record)}><Keyword data={text.substr(0, text.length - 1)} keyword={keyword} /></a>;
            }
        }
    ].filter(v => v);

    const rowSelection = {
        selectedRowKeys: rows.map(({ pkid }) => pkid),
        onChange: (selectedRowKeys, selectedRows) => {
            setRows(selectedRows);
        },
    }

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={dataList}
            pagination={Page.init(dataList)}
            rowKey={record => record.pkid}
            rowSelection={{ type: "checkbox", ...rowSelection }}
        />
    );
}

export default Pane;
