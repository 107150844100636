import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import useHTTP from "@/hooks/useHTTP.mjs";
import runAsync from '@/script/runAsync';

const Pane = ({ value, onChange, onCall, prjId, ...props }) => {
    const it = null;
    const http = useHTTP();
    const [prjPatternId, setPrjPatternId] = useState(value);
    const [data, setData] = useState([]);
    const [patterns, setPatterns] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doSearch();
    }, [prjId]);

    const doSearch = async () => {
        try {
            setLoading(true);
            const data = await runAsync(
                async () => {
                    return await http.get(`/prjpatterns`, { prjId });
                }, {
                error: { content: (err) => `查询失败: ${err.message}` },
                loading: { show: false },
                success: { show: false },
            });

            const options = [{ value: it, label: '迭代版' }];
            for (const prj of data) {
                options.push({ value: prj.pkid, label: prj.patternRev });
            }

            setPrjPatternId(it);
            setPatterns(data);
            setData(options);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Select
            {...props}
            showSearch
            loading={loading}
            options={data}
            value={prjPatternId}
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label == input)}
            onChange={(e) => {
                if (onChange) {
                    onChange(e);
                    setPrjPatternId(e);
                }

                const result = patterns.filter(v => v.pkid == e);
                if (result.length > 0) {
                    onCall(result[0].patternRev);
                } else {
                    onCall(null);
                }
            }}
        />
    );
}

export default Pane;