import 'antd/dist/antd.less'
import React, { useMemo, useEffect, useState } from 'react'
import { Breadcrumb, Button } from 'antd'
import { PreviewWidget } from '@/pages/MainPage/frames/FormilyMobile/widgets'
import { useSchemaService } from '@/pages/MainPage/frames/Formily/service'
import {
  transformToTreeNode,
} from '@designable/formily-transformer'
import openWindow from '@/script/openWindow.mjs';
import './styles.less'

const FormilyMobile = (props) => {
  const schemaService = useSchemaService(); 
  const [shcemaData, setSchemaData] = useState({})
  const params = new URLSearchParams(location.search.split('?')[1]);
  
  const jsonObject = {};
  for (const [key, value] of params.entries()) {
    jsonObject[key] = value;
  }
  const uvId = params.get("uvId"); 


  useEffect(() => {
    (async () => {
      const data = await schemaService.loadSchema(undefined, uvId)
      setSchemaData(data || {});
    })()
  }, [])
  const tree = useMemo(() => {return shcemaData && shcemaData.uvMap ? transformToTreeNode(JSON.parse(shcemaData.uvMap)): transformToTreeNode('')}, [shcemaData])
  return (
    <section className='formilyMobileRoot'>
      <PreviewWidget {...props}  tree={tree} />
    </section>
  )
}

export default FormilyMobile;
